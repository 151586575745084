// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-views-index-md": () => import("/opt/build/repo/docs/src/views/index.md" /* webpackChunkName: "component---src-views-index-md" */),
  "component---src-pages-colour-md": () => import("/opt/build/repo/docs/src/pages/colour.md" /* webpackChunkName: "component---src-pages-colour-md" */),
  "component---src-pages-copy-md": () => import("/opt/build/repo/docs/src/pages/copy.md" /* webpackChunkName: "component---src-pages-copy-md" */),
  "component---src-pages-letterhead-md": () => import("/opt/build/repo/docs/src/pages/letterhead.md" /* webpackChunkName: "component---src-pages-letterhead-md" */),
  "component---src-pages-email-signature-md": () => import("/opt/build/repo/docs/src/pages/email-signature.md" /* webpackChunkName: "component---src-pages-email-signature-md" */),
  "component---src-pages-logos-md": () => import("/opt/build/repo/docs/src/pages/logos.md" /* webpackChunkName: "component---src-pages-logos-md" */),
  "component---src-pages-photography-md": () => import("/opt/build/repo/docs/src/pages/photography.md" /* webpackChunkName: "component---src-pages-photography-md" */),
  "component---src-pages-typography-md": () => import("/opt/build/repo/docs/src/pages/typography.md" /* webpackChunkName: "component---src-pages-typography-md" */),
  "component---src-pages-presentation-md": () => import("/opt/build/repo/docs/src/pages/presentation.md" /* webpackChunkName: "component---src-pages-presentation-md" */)
}

exports.data = () => import("/opt/build/repo/docs/.cache/data.json")

